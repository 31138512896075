import React from 'react';
import Layout from '../shared/Layout';
import DoubleLayout from '../shared/DoubleLayout';
import Head from '../shared/Head';
import contactStyles from './contact.module.scss';

const Contact = () => {
    return (
        <Layout>
            <Head title={`Contact`}/>
            <DoubleLayout>
                <h1 className={contactStyles.pagetitle}>Contact</h1>
                <p>Dacă vrei vreodată să îmi scrii mă poți găsi la una dintre adresele de email de mai jos:</p>
                <h1 className={contactStyles.emaill}>alexpop3u [arrond]  gmail.com</h1>
                <h1 className={contactStyles.emaill}>contact [arrond]  mintwebtutsblog.ro</h1>
                <p>Nu promit ca îți voi răspunde dar mă voi stradui. :))))</p>
                <p>Dar ca să fim tot timpul în contact poți oricând să te abonezi la newsletter și vei auzi vești
                    de le mine în mod regulat.
                </p>
                <p>Cam asta este tot ce am avut de zis aici.</p>
                <p>Era să uit: dacă vrei vreodată să scrii un articol tehnic pe acest blog nu ezita să mă contactezi.
                    O sa îl public cu cea mai mare plăcere.
                </p>
                <p>O zi frumoasă îți doresc! </p>
            </DoubleLayout>
        </Layout>
    );
}
export default Contact;